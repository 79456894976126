import React from "react";
import './App.css';

import { BrowserRouter} from "react-router-dom";

import {
  createMemoryHistory
} from 'history'


import AppRoutes from "./routes"

const history = createMemoryHistory();

function App() {
  return (
    <BrowserRouter location={history.location} navigator={history}>
      <div className="App">
        <div className="auth-wrapper">
          <React.Suspense fallback={<></>}>
            <AppRoutes />
          </React.Suspense>
        </div>
      </div>
    </BrowserRouter>

  );
}

export default App;
