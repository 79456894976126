
import React, { lazy } from "react";
import {Routes, Route } from "react-router-dom";

const NotFound = lazy(() => import('./page/common/NotFound'))
const Login = lazy(() => import('./page/login/login'));
const ExamInput = lazy(() => import('./page/exam/input'));
const ExamExecute = lazy(() => import('./page/exam/execute'));
const ExamResult = lazy(() => import('./page/exam/result'));
const CompanyList = lazy(() => import('./page/company/companyList'));
const ClassList = lazy(() => import('./page/class/classList'));
const UserList = lazy(() => import('./page/user/userList'));
const TopicList = lazy(() => import('./page/topic/topicList'));
const PrivateRoute = lazy(() => import('./page/PrivateRoute'));
const RoleConfig = lazy(() => import('./page/role/roleConfig'));
const QuizList = lazy(() => import('./page/quiz/quizList'));
const ExamList = lazy(() => import('./page/manageExam/examList'))
const ReportList = lazy(() => import('./page/report/reportList'))
const ManageExamResult = lazy(() => import('./page/report/examResult'))
const Profile = lazy(() => import('./page/profile/profile'))


const AppRoutes = () => {
    return (
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/exam/:examCode" element={<ExamInput />} />
        <Route path="/exam/input/:examCode" element={<ExamInput />} />
        <Route path="/exam/execute" element={<ExamExecute />} />
        <Route path="/exam/result" element={<ExamResult />} />
        <Route path="/manage/company/list" element={<PrivateRoute><CompanyList /></PrivateRoute>} />
        <Route path="/manage/class/list" element={<PrivateRoute><ClassList /></PrivateRoute>} />
        <Route path="/manage/user/list" element={<PrivateRoute><UserList /></PrivateRoute>} />
        <Route path="/manage/role/config" element={<PrivateRoute><RoleConfig /></PrivateRoute>} />
        <Route path="/manage/topic/list" element={<PrivateRoute><TopicList /></PrivateRoute>} />
        <Route path="/manage/quiz/list" element={<PrivateRoute><QuizList /></PrivateRoute>} />
        <Route path="/manage/exam/list" element={<PrivateRoute><ExamList /></PrivateRoute>} />
        <Route path="/manage/exam/report" element={<PrivateRoute><ReportList /></PrivateRoute>} />
        <Route path="/manage/exam/report/:examId" element={<ManageExamResult isEdit={false} />} />
        <Route path="/manage/exam/report/:examId/edit" element={<ManageExamResult isEdit={true} />} />
        <Route path="/profile" element={<PrivateRoute isShowMenu={false}><Profile /></PrivateRoute>} />
      </Routes>
    );
  };
  
  export default AppRoutes;
